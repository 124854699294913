<template>
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    :width="width"
    :height="height"
    viewBox="0 0 83.4 83.4"
    :fill="color"
  >
    <g>
      <g>
        <path
          d="M57.301,25.6c0-0.6,0-1.2-0.101-1.7l-8.8,3.8h-14l-8.3-3.4c0,0.4-0.1,0.9-0.1,1.3c0,8.6,7,15.6,15.6,15.6
			C50.301,41.2,57.301,34.2,57.301,25.6z"
        />
        <path
          d="M13.9,62.2V78.6l1.2,0.301c2.4,0.8,4.8,1.4,7.1,1.9V53.9c0-4.801,2.8-9,6.8-11C20.3,45.1,13.9,52.9,13.9,62.2z"
        />
        <path
          d="M36.8,42.301l-1.3,2.5c-0.3,0.6-0.4,1.1-0.4,1.699v8.2c0,2.2,1.8,4,4,4h5.201c2.199,0,4-1.8,4-4v-8.2
			c0-0.6-0.101-1.199-0.4-1.699l-1.301-2.5H36.8z"
        />
        <path
          d="M52.5,46.7v8c0,4.5-3.699,8.2-8.199,8.2H39.1c-4.5,0-8.2-3.7-8.2-8.2v-8c-2.7,1.3-4.5,4.101-4.5,7.2v28
			c5.6,1.1,10.8,1.5,15.3,1.5s9.7-0.5,15.3-1.5v-28C57,50.801,55.2,48,52.5,46.7z"
        />
        <path
          d="M54.4,42.9c4.1,2,6.9,6.199,6.9,11V81c2.299-0.5,4.699-1.199,7.1-1.9l1.1-0.399V62.301C69.5,52.9,63.1,45.1,54.4,42.9z"
        />
        <path
          d="M41.7,12.2c2.601,0,3.899-2.3,3.899-5V0H41.7h-3.9v7.1C37.8,9.9,39.1,12.2,41.7,12.2z"
        />
        <path
          d="M34.8,24.7h13.7l10.4-4.2l0.9-3l-1-1C58.2,8.1,53.7,2.3,47.5,0.1v7.1c0,4-2.4,7.4-5.8,7.4c-3.4,0-5.8-3.3-5.8-7.4V0.1
			c-6.2,2.2-10.7,8-11.4,16.4l-1,1l0.9,3L34.8,24.7z"
        />
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {
  name: 'WorkerIcon',
  props: {
    height: { required: false },
    width: { required: false },
    color: { required: false }
  }
}
</script>

<style lang="scss" scoped>
svg {
  //   fill: #404346;
  overflow: hidden;
  vertical-align: text-top;
}
</style>
